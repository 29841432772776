<template>
  <div>
    <nav-bread />
    <div class="content">
      <v-md-preview ref="preview" :text="md" id="articleContent"  v-viewer/>
      <div class="catalog">
        <h1>TaskMan APS</h1>
        <el-tree
          :data="titles"
          :props="defaultProps"
          accordion
          :render-content="renderContent"
          class="tree"
          @node-click="handleNodeClick" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import NavBread from '@/components/NavBread.vue'
const URL = `${process.env.BASE_URL}doc/aps.md`
export default {
  components: {
    NavBread
  },
  data() {
    return {
      md: '',
      titles: [],
      title: {},
      point: 0,
      isShow: false,
      defaultProps: {
        children: 'list',
        label: 'title'
      }
    }
  },
  created() {
    this.getContent()
  },
  mounted() {
    this.$once('hook:updated', function () {
      // 获取需要的标签
      const anchors = this.$refs.preview.$el.querySelectorAll('h1,h2,h3,h4')
      const titles = Array.from(anchors).filter((title) => !!title.innerText.trim())
      if (!titles.length) {
        this.titles = []
        return
      }
      const arr = []
      var count = 0
      titles.forEach((item) => {
        // console.log(item)
        const param = { title: item.innerText, lineIndex: item.getAttribute('data-v-md-line'), indent: item.tagName }
        // 如果空，添加一个值，一般来说为H1
        if (arr.length === 0) {
          arr.push({ ...param, list: [] })
        } else {
          // 判断标题，非H1和H2添加到当前项
          if (item.tagName === 'H2' || item.tagName === 'H1') {
            count++
            arr.push({ ...param, list: [] })
          } else {
            arr[count].list.push(param)
          }
        }
      })
      this.titles = arr
    })

    // this.$once('hook:updated', function () {
    //   this.addImgEvent('articleContent')
    // })
  },
  methods: {
    getContent() {
      axios
        .get(URL, {})
        .then((res) => {
          // console.log(res.data)
          this.md = res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    renderContent(h, { node, data }) {
      if (node.level === 2) {
        return h(
          'span',
          {
            class: data.indent
          },
          data.title
        )
      }
      return h('span', data.title)
    },
    handleNodeClick(data) {
      const { preview } = this.$refs
      const { lineIndex } = data
      const heading = preview.$el.querySelector(`[data-v-md-line="${lineIndex}"]`)
      if (heading) {
        heading.scrollIntoView(true)
      }
    },
    addImgEvent(id) {
      // const that = this
      const imgList = document.getElementById(id)?.getElementsByTagName('img')
      const preview = document.getElementById(id)
      var mask = document.createElement('div')
      mask.style.width = window.innerWidth + 'px'
      mask.style.height = window.innerHeight + 'px'
      mask.style.background = '#fff'
      mask.style.opacity = '.9'
      mask.style.position = 'fixed'
      mask.style.top = '0'
      mask.style.left = '0'
      mask.style.zIndex = 10086
      var maskImg = document.createElement('img')
      maskImg.style.position = 'absolute'
      maskImg.style.top = '50%'
      maskImg.style.left = '50%'
      maskImg.style.transform = 'translate(-50%, -50%) scale(2)'
      maskImg.style.zIndex = 10087

      if (!imgList) {
        return
      }
      for (var i = 0; i < imgList.length; i++) {
        imgList[i].onclick = function () {
          // if (this.classList.contains('active')) {
          //   this.classList.remove('active')
          // } else {
          //   this.classList.add('active')
          // }
          mask.style.display = 'block'
          maskImg.src = this.src
          mask.append(maskImg)
          preview.append(mask)
        }
      }

      mask.addEventListener('click', () => {
        console.log(mask)
        mask.style.display = 'none'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  // position: relative;
  background: #323232;
  display: flex;
  margin: 2vh 2vw;
  border-radius: 10px;
  ::v-deep .catalog {
    flex: 1;
    height: 75vh;
    font-size: 16px;
    > h1 {
      border-bottom: 1px solid #999;
      font-size: 24px;
      padding-top: 10px;
      text-align: left;
    }
    .el-tree {
      margin: 20px;
      list-style: none;
      color: #999;
      background: transparent !important;
      > .el-tree-node {
        margin: 10 0;
        // &:first-child {
        //   border-bottom: 1px solid #999;
        //   font-size: 24px;
        //   padding-bottom: 10px;
        //   .el-tree-node__content {
        //     .is-leaf {
        //       display: none;
        //     }
        //   }
        // }
      }
      .el-tree-node {
        &:focus {
          > .el-tree-node__content {
            background: rgb(54, 54, 54, 0.8);
          }
        }
        .el-tree-node__content:hover {
          background: transparent;
          color: #2deb79;
        }
        .H4 {
          margin: 15px;
        }
      }
    }
  }

  ::v-deep .v-md-editor-preview {
    position: relative;
    background: #323232;
    margin: 20px 20px;
    border-radius: 10px;
    height: 75vh;
    flex: 3;

    .vuepress-markdown-body {
      position: absolute;
      // width: 60vw;
      height: 68vh;
      border-radius: 10px;
      background: #323232;
      color: #ffffff;
      margin-top: 1vh;
      padding-bottom: 32px !important;
      padding-top: 32px !important;
      overflow: auto;
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      ul,
      li {
        text-align: left;
        color: #ffffff;
      }
      > p {
        strong {
          font-weight: 700;
          font-size: 16px;
        }
        img {
          border-radius: 4px;
          box-shadow: 0 2px 5px 0 rgba(35, 36, 38, 0.12), 0 2px 10px 0 rgba(35, 36, 38, 0.08);
          transition: all ease 0.5s;

          &.active {
            position: absolute;
            left: 50%;
            transform: translateX(-50%) scale(2);
            z-index: 100;
          }
        }
      }
      center {
        margin: 10px auto;
        img {
          border-radius: 8px;
          box-shadow: 0 2px 5px 0 rgba(35, 36, 38, 0.12), 0 2px 10px 0 rgba(35, 36, 38, 0.08);
        }
        div {
          border-bottom: 1px solid #d9d9d7;
          display: inline-block;
          color: #999;
          padding: 2px;
          font-size: 14px;
        }
      }
      h1 {
        border-bottom: 1px solid #eaecef;
        padding-bottom: 10px;
        font-size: 30px;
        margin-bottom: 1.25em;
      }
      h2 {
        border-bottom: 0px;
        margin: 1em 0;
        font-size: 26px;
      }
      h3 {
        font-size: 22px;
      }
      table,
      th,
      td {
        margin-left: auto;
        margin-right: auto;
      }
      table {
        display: revert;
        tr:nth-child(2n) {
          background: #999;
          color: #363636;
        }
      }
      blockquote {
        font-size: 16px;
        border-left: 5px solid #dfe2e5;
        margin: 10px 0;
        > p {
          margin-left: 10px;
        }
      }
    }

    // 标题
    ::-webkit-scrollbar {
      width: 5px;
      border-radius: 2px;
      -webkit-appearance: none;
      // background: #363636;
    }
    ::-webkit-scrollbar-track {
      background: rgba(117, 117, 117, 0.1);
      border-radius: 0;
    }
    ::-webkit-scrollbar-thumb {
      cursor: pointer;
      border-radius: 5px;
      background: rgba(86, 86, 87, 0.25);
      transition: color 0.2s ease;
      -webkit-transition: color 0.2s ease;
      -moz-transition: color 0.2s ease;
      -ms-transition: color 0.2s ease;
      -o-transition: color 0.2s ease;
    }
  }
}
</style>
